.basic-swipeable-list__container {
    width: 120%;
    margin: 16px 0;
    outline-color: gray;
    outline-style: solid;
    outline-width: 1px 0;
}

.icon {
    fill: white;
    width: 32px;
    height: 32px;
    align-self: center;
}

.swipe-action__trailing > * {
    justify-content: flex-end;
}

/*
@keyframes content-return-trailing-ms {
    40% {
        transform: translateX(-100%) !important;
    }

    100% {
        transform: translateX(0) !important;
    }
}

@keyframes actions-return-ms {
    40% {
        width: 200px !important;
    }

    100% {
        width: 0 !important;
    }
}

.swipeable-list-item__trailing-actions--scaled > * {
    transform: scale(1.5) !important;
    transform-origin: center right !important;
    transition: transform 0.2s ease-in !important;
}


.swipeable-list-item__trailing-actions--return {
    transition: width 0.5s ease-in-out;
}*/

/*<div 
class="
swipeable-list-item__trailing-actions 
swipeable-list-item__trailing-actions--return 
test-actions-opened

" data-testid="trailing-actions" style="width: 186px;" > <span class="swipe-action swipe-action__trailing" > <div class="css-5svouq" style="background-color: rgb(74, 123, 43);" > Edit</div > </span > <span class="swipe-action swipe-action__trailing" > <div class="css-5svouq" style="background-color: rgb(188, 40, 30);" > <div class="css-1bku0gi" > <span class="icon" > <svg height="100%" viewBox="0 0 24 24" width="100%" xmlns="http://www.w3.org/2000/svg" > <path d="M0 0h24v24H0V0z" fill="none" > </path > <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" > </path > </svg > </span > Delete</div > </div > </span > </div >
*/